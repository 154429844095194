<template>
  <div class="right-menu" v-loading.fullscreen.lock="subscriptionLoader">
    <subHeader pageName="SUBSCRIPTION" />

    <tutorialsAction moduleName="SUBSCRIPTION"/>

    <div class="packageSubscriptionSection pt-0">
      <div class="db-container">
        <div class="packageSubscriptionblock" v-if="subscriptionLoader">
          <div class="subscriptionPackageList">
            <ul>
              <li v-for="i in 7" :key="i">
                <vue-skeleton-loader
                  type="rect"
                  :width="350"
                  :height="40"
                  animation="fade"
                />
              </li>
            </ul>
          </div>
          <div class="subscriptionAllPackage">
            <div class="planChangeBtn">
              <div class="planChangeToggle">
                <vue-skeleton-loader
                  type="square"
                  :width="200"
                  :height="50"
                  animation="fade"
                />

                <!-- <p>Monthly</p>
                <div class="planToggleBtn">
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="selectedSubscriptionType"
                      true-value="annual"
                      false-value="monthly"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
                <p class="tagLine">Annual <span>20% Discount</span></p> -->
              </div>
            </div>

            <div class="subscriptionWhiteBox firstChildBlock" v-for="i in 5" :key="i">
              <div class="subscriptionTitle">
                <h4>
                  <vue-skeleton-loader
                    type="rect"
                    :width="110"
                    :height="43"
                    animation="fade"
                  />
                </h4>
                <p>
                  <vue-skeleton-loader
                    type="rect"
                    :width="110"
                    :height="63"
                    animation="fade"
                  />
                </p>
              </div>
              <div class="subscriptionPrice">
                <vue-skeleton-loader
                  type="rect"
                  :width="230"
                  :height="40"
                  animation="fade"
                />
              </div>
              <div class="checkSubscriptionIconList">
                <ul>
                  <li v-for="i in 7" :key="i">
                    <vue-skeleton-loader
                      type="rect"
                      :width="230"
                      :height="23"
                      animation="fade"
                    />
                  </li>
                </ul>
              </div>
              <div class="packageSubscriptionBtn">
                <vue-skeleton-loader
                  type="rect"
                  :width="230"
                  :height="38"
                  animation="fade"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="packageSubscriptionblock" v-else>
          <div class="subscriptionPackageList">
            <ul>
              <li v-for="(feature,index) in featuresList" :key="index">
                {{ feature }}
              </li>
            </ul>
          </div>
          <div class="subscriptionAllPackage">
            <div class="planChangeBtn">
              <div class="planChangeToggle">
                <p>Monthly</p>
                <div class="planToggleBtn" id="package-switch">
                  <label class="switch">
                    <input type="checkbox" v-model="selectedSubscriptionType" true-value="annual" false-value="monthly" :disabled="requestLoader || tourIsActive"/>
                    <span class="slider"></span>
                  </label>
                </div>
                <p class="tagLine">Annual</p>
              </div>
              <div class="planDiscountWrap" v-if="maxDiscountValue != 0">
                <p class="tagLine"><span>Save upto {{ maxDiscountValue }}% when you pay annually.</span></p>
              </div>
              <!-- <p>Monthly</p>
              <div class="planToggleBtn" id="package-switch">
                <label class="switch">
                  <input type="checkbox" v-model="selectedSubscriptionType" true-value="annual" false-value="monthly" :disabled="requestLoader || tourIsActive"/>
                  <span class="slider"></span>
                </label>
              </div> -->
              <!-- <p class="tagLine">Annual <span v-if="maxDiscountValue != 0">upto {{ maxDiscountValue }}% Discount</span></p> -->
            </div>
            <div class="subscriptionWhiteBox" :class="pkg.isPrimary == 1 ? 'primary' : ''" v-for="(pkg,index) in packagesList" :key="index" id="package-details-box">
              <div class="subscriptionTitle">
                <h4>{{ pkg.name }}</h4>
                <el-tooltip class="box-item" effect="dark" :content="pkg.remarks" placement="top-start" v-if="pkg.remarks.length > 40">
                  <p>{{ pkg.remarks.length > 40 ? pkg.remarks.substr(0, 40) + "..." : pkg.remarks }}</p>
                </el-tooltip>
                <p v-else>{{ pkg.remarks }}</p>
              </div>
              <div class="subscriptionPrice">
                <!-- <h4><span>$ </span>{{ selectedSubscriptionType == 'annual' ? (pkg.yearly_price).toFixed(2) : (pkg.monthly_price).toFixed(2) }}</h4> -->
                <!-- <p>per shop/ {{ selectedSubscriptionType == 'annual' ? 'year' : 'month' }}</p> -->
                <h4>{{ packagePricing(pkg) }}</h4>
                <p>{{ pkg.package_type == 'fixed' ? 'per shop / month' : 'get in touch with us' }}</p>
              </div>
              <div class="checkSubscriptionIconList">
                <ul>
                  <li v-for="(pkgfeature,index) in pkg.features" :key="index" v-html="pkgfeature.description">
                  </li>
                </ul>
              </div>
              <div
                class="packageSubscriptionBtn"
                v-if="
                  subscription.length != 0 &&
                  subscription.type == selectedSubscriptionType &&
                  subscription.package.name == pkg.name
                "
              >
                <button disabled id="current-package">Current Package</button>
              </div>
              <div
                class="packageSubscriptionBtn"
                v-else-if="
                  (subscription.length != 0 && subscription.type == selectedSubscriptionType && getPkgIndex(pkg)) ||
                  (subscription.length != 0 && subscription.type == selectedSubscriptionType && getPkgIndex(pkg))
                "
              >
                <button
                  :disabled="subscription.last_request != null || index == 0"
                  @click="downGradePopup"
                  id="downgrade-package"
                >
                  Downgrade
                </button>
              </div>
              <div
                class="packageSubscriptionBtn"
                v-else-if="
                  subscription.length != 0 &&
                  subscription.last_request != null &&
                  subscription.last_request.subscription_type == selectedSubscriptionType &&
                  subscription.last_request.package.name ==  pkg.name
                "
              >
                <loaderBtn v-if="cancelLoader"/>
                <button @click="subscriptionRequestCancellationHandle" v-else>Cancel Request</button>
                <p>
                  Your Request has been submitted against this package. Admin
                  will respond within 24hrs
                </p>
              </div>
              <div class="packageSubscriptionBtn" v-else>
                <loaderBtn v-if="requestLoader && selectedPackage.id == pkg.id"/>
                <button
                  :disabled="subscription.last_request != null || index == 0 || tourIsActive || requestLoader || (pkg.package_type == 'custom')"
                  @click.prevent="initiateSubscriptionChangeRequest(pkg)"
                  id="upgrade-package"
                  v-else
                >
                  {{ pkg.package_type == 'fixed' ? "Upgrade Now" : "Let's Talk" }} 
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- payment information popup starts here -->
        <div class="modalBg subscriptionModal" :class="paymentPopup == true ? 'modal-active' : ''">
          <div class="modalLayers" @click.prevent="paymentPopup = false"></div>
          <div class="modalBody" id="payment-modal">
            <div class="subscriptionModalTitle">
              <h4>Payment Information</h4>
            </div>
            <div class="subscriptionPaymentInfoList">
              <ul>
                <li>
                  <div class="row">
                    <div class="col-md-6 cardSvgs">
                      
                      <svg height="40px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="40px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="_x31_6-amex_x2C__Credit_card">
                          <g>
                            <g>
                              <path d="M432.121,329.196v-3.595c-8.621,4.47-3.114,3.595-125.077,3.595c0-20.117,0.081-19.078,0-20.117     C305.688,309,304.49,309,299.54,309c0,14.287-0.078,5.428-0.078,20.196h-31.608c0-9.66,0.08-12.213,0.08-23.31     c-7.982,4.79-18.199,5.109-27.379,4.949c0,11.732-0.08,6.626-0.08,18.36h-39.03c-4.071-4.553-2.156-2.478-12.292-13.891     c-2.556,2.794-10.218,11.097-12.852,13.891H110.85v-73.675h66.329c3.991,4.471,2.235,2.475,12.371,13.728     c2.555-2.792,9.738-10.696,12.533-13.728h46.295c7.821,0,14.368,1.518,19.396,4.471v-4.471c43.342,0,51.324-1.118,60.424,4.07     v-4.07h62.419v4.15c9.099-5.508,15.644-4.15,51.802-4.15v3.991c8.222-4.71,13.249-4.15,43.343-3.991V115.519     c0-21.152-17.161-38.314-38.315-38.314H64.314c-21.152,0-38.313,17.162-38.313,38.314v87.641     c7.504-17.48,15.725-36.716,18.438-43.021h31.689c3.433,8.061,1.276,2.952,7.184,16.841v-16.841h36.717     c2.314,4.947,8.86,19.155,11.095,23.945c4.629-10.856,8.063-19.078,10.058-23.945h82.213c0-0.081,9.18,0,9.26,0     c34.88,0.158,42.782-0.64,51.402,4.229v-4.229h31.85v7.422c6.065-4.869,14.288-7.422,24.503-7.422h22.03     c0,0.399,1.519,0.239,1.837,0.239h25.86c3.354,7.821,2.076,4.788,7.023,16.442v-16.442h34.563     c3.911,6.385-0.798-1.437,8.938,14.686v-14.686h31.849v73.434h-33.204c-4.312-7.185-1.118-1.757-10.536-17.48v17.48h-42.146     c-5.107-11.814-0.081-0.24-5.268-12.213h-15.167c-3.351,7.981-1.755,4.151-5.107,12.213h-21.393     c-9.815,0-17.799-2.395-23.705-7.104v7.104h-53.079c-0.24-11.095-0.081-19.796-0.081-19.796     c-1.437-0.239-2.715-0.159-7.822-0.159v20.034H146.848v-9.1c-1.996,4.47-2.155,4.71-4.071,9.1H119.23     c-3.193-7.104-2.314-5.108-4.07-9.1v9.1H72.935c-3.353-8.062-1.756-4.23-5.108-12.212H52.501     c-3.352,7.981-1.755,4.15-5.108,12.212H26.16V396.48c0,21.155,17.162,38.315,38.314,38.315h383.212     c21.15,0,38.313-17.16,38.313-38.315v-72.154C475.862,330.95,459.897,329.196,432.121,329.196z" style="fill:#016FD0;"/>
                              <rect height="55.313" style="fill:#016FD0;" width="13.489" x="293.235" y="170.754"/>
                              <path d="M285.652,185.599c0-13.09-11.254-14.685-21.87-14.685l-31.209-0.241v55.314h13.09v-20.034h14.368     c14.687,0,11.573,8.222,11.813,20.034h13.251v-10.776c0-7.342-1.198-12.052-8.781-14.685     C282.222,198.131,285.732,191.985,285.652,185.599z M262.187,194.619h-16.523v-12.213h16.763c4.071,0,8.539,0.799,8.539,5.906     C270.965,193.581,266.734,194.619,262.187,194.619z" style="fill:#016FD0;"/>
                              <polygon points="223.474,182.406 223.474,170.754 179.175,170.754 179.175,226.067 223.474,226.067      223.474,214.653 192.424,214.653 192.424,203.639 222.596,203.639 222.596,192.384 192.424,192.384 192.424,182.406    " style="fill:#016FD0;"/>
                              <path d="M169.357,226.067v-55.313h-22.031l-15.724,37.514l-17.322-37.514H92.65v52.439l-22.429-52.439     H50.665l-23.707,55.313h14.289l5.107-12.212h27.538l5.108,12.212h26.979v-43.262l19.156,43.262h11.654l19.156-43.262v43.262     H169.357z M51.063,202.361l8.94-22.03l9.18,22.03H51.063z" style="fill:#016FD0;"/>
                              <path d="M248.856,266.058h-42.064l-16.763,18.197l-16.363-18.197h-53.078l-0.081,55.313h52.203l17-18.357     l16.283,18.357h25.702l0.079-18.596c15.086,0,39.352,3.67,39.352-18.598C271.126,270.367,261.308,266.058,248.856,266.058z      M166.004,309.718h-32.407v-11.015h28.975V287.45h-28.975v-9.979h33.285l14.288,16.125L166.004,309.718z M218.524,316.264     l-20.193-22.429l20.193-21.871V316.264z M248.697,291.521h-16.923v-14.05h17.161c4.471,0,8.143,1.836,8.143,6.707     C257.078,289.284,253.406,291.521,248.697,291.521z" style="fill:#016FD0;"/>
                              <path d="M313.191,198.85c0,17.719,8.141,27.138,26.339,27.138h15.328l5.105-12.212h27.379l5.267,12.212     h26.901v-41.426l24.902,41.426h18.839v-55.073h-13.491v38.393l-23.228-38.393h-20.194v52.2l-22.268-52.2h-19.796l-18.758,43.5     h-5.906c-10.617,0-12.851-6.466-12.851-15.884c0-18.997,12.531-15.964,26.419-15.725v-12.133h-12.852     C321.572,170.673,313.191,180.251,313.191,198.85z M373.934,180.331l8.938,22.03h-18.198L373.934,180.331z" style="fill:#016FD0;"/>
                              <path d="M415.597,309.479h-25.699l-0.081,11.813h25.703c11.813,0,20.912-4.47,20.912-17.559     c0-26.501-34.241-8.94-34.241-20.995c0-4.468,3.91-5.106,7.342-5.106h24.266v-11.655h-26.5c-10.217,0-18.758,5.27-18.758,16.522     c0,26.341,34.081,9.979,34.081,21.872C422.544,308.682,418.87,309.479,415.597,309.479z" style="fill:#016FD0;"/>
                              <path d="M330.591,280.984c0-13.091-11.254-14.927-21.63-14.927h-31.45l-0.078,55.313h13.25l0.077-20.192     h14.05c8.78,0,11.813,1.597,11.813,11.015l-0.08,9.178h13.25l0.081-11.014c0-7.104-1.438-12.053-8.782-14.687     C327.239,293.195,330.513,287.05,330.591,280.984z M307.284,289.923H290.76v-12.452h16.764c4.071,0,8.541,0.8,8.541,5.907     C316.064,288.886,311.755,289.923,307.284,289.923z" style="fill:#016FD0;"/>
                              <polygon points="381.915,277.471 381.915,266.058 337.854,266.058 337.775,321.371 381.836,321.371      381.915,309.957 351.104,309.718 351.104,298.703 381.356,298.703 381.356,287.45 351.185,287.45 351.185,277.471    " style="fill:#016FD0;"/>
                              <path d="M461.097,277.711h24.025v-11.653H458.86c-10.217,0-18.996,5.268-18.996,16.523     c0,26.34,34.082,10.218,34.082,21.87c0,4.07-3.432,5.108-6.704,5.108H441.7l-0.08,11.812h25.542     c6.705,0,14.049-1.438,17.96-7.103v-20.595c-8.383-11.014-31.369-1.037-31.369-10.774     C453.753,278.271,457.423,277.711,461.097,277.711z" style="fill:#016FD0;"/>
                            </g>
                          </g>
                        </g>
                        <g id="Layer_1"/>
                      </svg>

                      <svg enable-background="new 0 0 87 56" height="50px" id="Layer_1" version="1.1" viewBox="0 0 87 56" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                          <path d="M86,52.636C86,53.941,84.84,55,83.407,55H3.593C2.161,55,1,53.941,1,52.636V3.365C1,2.059,2.161,1,3.593,1   h79.814C84.84,1,86,2.059,86,3.365V52.636z" fill="#F1F2F2"/>
                          <g>
                            <path d="M39.349,8.834H25.708l13.56,0.003C39.296,8.836,39.323,8.834,39.349,8.834z" fill="none"/>
                            <path d="M13.5,44.588v2.578h2.887C14.765,47.14,13.637,46.064,13.5,44.588z" fill="none"/>
                            <path d="M55.761,8.842L49.853,8.84l5.875,0.003C55.739,8.843,55.751,8.842,55.761,8.842z" fill="#DE4447"/>
                            <path d="M27.222,43.46l6.99-30.919c0.459-2.021,3.021-3.661,5.056-3.704l-13.56-0.002    c-2.046,0-4.671,1.658-5.139,3.706L13.58,43.46c-0.043,0.188-0.066,0.372-0.08,0.554v0.574c0.137,1.477,1.265,2.552,2.887,2.578    h13.641C28.015,47.134,26.76,45.484,27.222,43.46z" fill="#EC1C24"/>
                            <path d="M49.853,8.84L39.349,8.834c-0.026,0-0.053,0.001-0.081,0.003L49.853,8.84z" fill="#2A557C"/>
                            <path d="M43.721,43.46l6.987-30.919c0.46-2.007,2.989-3.635,5.019-3.698L49.853,8.84L39.268,8.837    c-2.035,0.042-4.597,1.682-5.056,3.704l-6.99,30.92c-0.462,2.023,0.793,3.673,2.806,3.705h16.5    C44.514,47.134,43.262,45.484,43.721,43.46z" fill="#0071BB"/>
                            <path d="M70.54,8.844L55.766,8.842c-0.003,0-0.005,0-0.005,0c-0.01,0-0.021,0.001-0.033,0.001    c-2.03,0.063-4.56,1.69-5.02,3.698l-6.986,30.92c-0.459,2.023,0.793,3.673,2.807,3.705h1.998h9.154h4.37    c1.985-0.1,3.913-1.708,4.365-3.694l6.988-30.918C73.87,10.507,72.588,8.844,70.54,8.844z" fill="#009DA4"/>
                            <path d="M39.553,31.366h0.257c0.236,0,0.394-0.079,0.469-0.235l0.667-0.999h1.786l-0.372,0.656h2.14l-0.271,1.006    h-2.547c-0.294,0.442-0.655,0.649-1.089,0.627h-1.327L39.553,31.366 M39.261,32.808h4.69L43.653,33.9h-1.887l-0.287,1.055h1.835    l-0.298,1.093H41.18l-0.426,1.559c-0.105,0.261,0.033,0.378,0.415,0.352h1.495l-0.276,1.014h-2.873    c-0.545,0-0.731-0.311-0.561-0.935l0.545-1.989h-1.174l0.297-1.093h1.173l0.288-1.054h-1.121L39.261,32.808z M46.747,30.127    l-0.074,0.639c0,0,0.886-0.665,1.688-0.665h2.971l-1.137,4.11c-0.094,0.472-0.498,0.703-1.211,0.703h-3.366l-0.788,2.889    c-0.046,0.155,0.019,0.235,0.188,0.235h0.662l-0.243,0.894h-1.684c-0.646,0-0.915-0.194-0.807-0.583l2.226-8.221L46.747,30.127    L46.747,30.127z M49.261,31.287h-2.649l-0.318,1.11c0,0,0.442-0.319,1.181-0.331c0.735-0.012,1.575,0,1.575,0L49.261,31.287z     M48.302,33.862c0.196,0.026,0.306-0.053,0.317-0.235l0.164-0.585h-2.655l-0.223,0.82H48.302z M46.512,35.189h1.53l-0.029,0.662    h0.407c0.205,0,0.308-0.067,0.308-0.196l0.12-0.428h1.271l-0.17,0.624c-0.143,0.521-0.524,0.791-1.142,0.817h-0.815l-0.003,1.134    c-0.016,0.182,0.148,0.272,0.486,0.272h0.765l-0.246,0.896h-1.838c-0.516,0.023-0.768-0.22-0.762-0.741L46.512,35.189z" fill="#FFFFFF"/>
                            <path d="M53.014,31.413l0.355-1.251h1.795l-0.078,0.46c0,0,0.917-0.46,1.578-0.46s2.219,0,2.219,0l-0.354,1.251    H58.18l-1.673,5.889h0.35l-0.331,1.17h-0.35l-0.146,0.511h-1.737l0.145-0.511h-3.428l0.334-1.17h0.344l1.675-5.889H53.014     M54.951,31.413l-0.457,1.594c0,0,0.781-0.302,1.454-0.384c0.148-0.56,0.343-1.21,0.343-1.21H54.951z M54.283,33.754l-0.461,1.67    c0,0,0.868-0.427,1.463-0.462c0.17-0.645,0.343-1.207,0.343-1.207L54.283,33.754L54.283,33.754z M54.618,37.302l0.343-1.21h-1.339    l-0.346,1.21H54.618z M58.957,30.086h1.687l0.071,0.624c-0.01,0.158,0.084,0.231,0.283,0.231h0.299l-0.302,1.055h-1.239    c-0.475,0.026-0.718-0.155-0.74-0.545L58.957,30.086z M58.464,32.348h5.462l-0.32,1.134h-1.739l-0.299,1.052h1.739l-0.324,1.128    h-1.935l-0.438,0.665h0.948l0.218,1.325c0.026,0.132,0.144,0.196,0.345,0.196h0.293l-0.31,1.093h-1.041    c-0.539,0.026-0.817-0.155-0.841-0.546l-0.252-1.209l-0.861,1.286c-0.203,0.366-0.517,0.536-0.94,0.511h-1.59l0.31-1.093h0.496    c0.204,0,0.374-0.091,0.526-0.275l1.349-1.952h-1.738l0.322-1.128h1.888l0.298-1.051h-1.887L58.464,32.348z" fill="#FFFFFF"/>
                            <path d="M27.97,24.649c-0.207,1.016-0.688,1.797-1.434,2.353c-0.739,0.545-1.693,0.819-2.861,0.819    c-1.099,0-1.904-0.28-2.418-0.839c-0.356-0.398-0.534-0.904-0.534-1.515c0-0.253,0.031-0.524,0.091-0.817l1.245-5.999h1.878    l-1.227,5.931c-0.038,0.165-0.053,0.317-0.051,0.454c-0.002,0.303,0.074,0.552,0.227,0.747c0.223,0.289,0.583,0.432,1.086,0.432    c0.578,0,1.055-0.142,1.423-0.426c0.37-0.283,0.611-0.685,0.72-1.207l1.231-5.931h1.869L27.97,24.649" fill="#FFFFFF"/>
                            <path d="M29.702,22.287h1.328l-0.152,0.773l0.19-0.221c0.431-0.46,0.953-0.688,1.571-0.688    c0.558,0,0.96,0.163,1.213,0.487c0.249,0.327,0.318,0.778,0.2,1.357l-0.731,3.648h-1.364l0.661-3.307    c0.068-0.341,0.049-0.595-0.056-0.761c-0.104-0.164-0.301-0.245-0.587-0.245c-0.352,0-0.646,0.11-0.887,0.327    c-0.242,0.218-0.401,0.523-0.479,0.91l-0.609,3.077h-1.367L29.702,22.287" fill="#FFFFFF"/>
                            <path d="M44.921,22.287h1.328l-0.15,0.773l0.188-0.221c0.431-0.46,0.956-0.688,1.57-0.688    c0.558,0,0.962,0.163,1.213,0.487c0.247,0.327,0.319,0.778,0.197,1.357l-0.727,3.648h-1.366l0.66-3.307    c0.068-0.341,0.049-0.595-0.055-0.761c-0.107-0.164-0.301-0.245-0.586-0.245c-0.352,0-0.646,0.11-0.889,0.327    c-0.242,0.218-0.401,0.523-0.479,0.91l-0.61,3.077h-1.364L44.921,22.287" fill="#FFFFFF"/>
                            <path d="M35.862,22.289h1.472l-1.153,5.354h-1.468L35.862,22.289 M36.326,20.338h1.484l-0.277,1.299h-1.484    L36.326,20.338z" fill="#FFFFFF"/>
                            <path d="M38.636,27.236c-0.385-0.369-0.579-0.864-0.581-1.494c0-0.109,0.007-0.232,0.021-0.365    c0.015-0.137,0.032-0.268,0.058-0.391c0.174-0.868,0.547-1.56,1.118-2.068c0.572-0.511,1.262-0.767,2.069-0.767    c0.661,0,1.185,0.184,1.569,0.553c0.385,0.372,0.577,0.873,0.577,1.512c0,0.109-0.008,0.235-0.022,0.371    c-0.017,0.138-0.038,0.271-0.061,0.399c-0.171,0.857-0.542,1.54-1.114,2.041c-0.573,0.504-1.261,0.754-2.062,0.754    C39.543,27.781,39.02,27.599,38.636,27.236 M41.44,26.175c0.26-0.281,0.446-0.707,0.558-1.275c0.017-0.088,0.032-0.18,0.042-0.272    c0.01-0.091,0.013-0.176,0.013-0.253c0-0.33-0.083-0.587-0.252-0.768c-0.168-0.183-0.407-0.274-0.715-0.274    c-0.408,0-0.74,0.144-1,0.431c-0.263,0.286-0.449,0.721-0.566,1.298c-0.016,0.088-0.029,0.177-0.041,0.264    c-0.01,0.088-0.013,0.172-0.011,0.248c0,0.329,0.084,0.58,0.253,0.76c0.168,0.179,0.405,0.268,0.718,0.268    C40.849,26.6,41.181,26.458,41.44,26.175" fill="#FFFFFF"/>
                            <path d="M51.486,18.967h3.857c0.741,0,1.313,0.168,1.708,0.498c0.392,0.333,0.589,0.813,0.589,1.437v0.019    c0,0.118-0.009,0.253-0.02,0.398c-0.019,0.144-0.042,0.289-0.074,0.44c-0.171,0.826-0.565,1.489-1.175,1.993    c-0.611,0.502-1.335,0.754-2.168,0.754h-2.069l-0.639,3.138h-1.793L51.486,18.967 M52.45,22.999h1.716    c0.446,0,0.801-0.104,1.061-0.309c0.255-0.208,0.425-0.524,0.52-0.955c0.015-0.079,0.025-0.151,0.037-0.217    c0.006-0.062,0.012-0.125,0.012-0.185c0-0.307-0.108-0.53-0.328-0.669c-0.219-0.142-0.563-0.209-1.037-0.209h-1.457L52.45,22.999z    " fill="#FFFFFF"/>
                            <path d="M65.653,28.68c-0.565,1.203-1.104,1.907-1.421,2.232c-0.316,0.322-0.947,1.075-2.458,1.017l0.129-0.917    c1.274-0.393,1.963-2.163,2.355-2.944l-0.469-5.766l0.984-0.013h0.826l0.091,3.617l1.548-3.617h1.569L65.653,28.68" fill="#FFFFFF"/>
                            <path d="M61.271,22.724l-0.623,0.429c-0.651-0.51-1.245-0.825-2.392-0.293c-1.562,0.725-2.868,6.28,1.433,4.451    l0.246,0.29l1.691,0.044l1.112-5.047L61.271,22.724 M60.308,25.485c-0.271,0.801-0.879,1.331-1.354,1.181    c-0.475-0.148-0.645-0.922-0.369-1.725c0.272-0.804,0.882-1.333,1.354-1.182C60.413,23.907,60.585,24.682,60.308,25.485" fill="#FFFFFF"/>
                          </g>
                        </g>
                      </svg>                      
                      
                      <svg enable-background="new 0 0 64 64" height="45px" id="Layer_1" version="1.1" viewBox="0 0 64 64" width="45px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                          <g>
                            <path d="M9.5,9.6C4.7,9.6,1,12.7,1,18.1v15.4c0.4,1.9,2.4,3.3,4.6,3.3c2.5,0,4.3-1.8,4.3-4V25h6.8v7.7    c0,4.3-5.5,6.5-9.5,6.5c-2.3,0-4.5-0.7-6.1-1.9v17h10.8c3.8,0,8.2-3.6,8.2-8.2V9.6H9.5z" fill="#1174CE"/>
                          </g>
                          <g>
                            <path d="M1,18.1v15.4c0.4,1.9,2.4,3.3,4.6,3.3c2.5,0,4.3-1.8,4.3-4V25h6.8v7.7c0,4.3-5.5,6.5-9.5,6.5    c-2.3,0-4.5-0.7-6.1-1.9v17h10.8c3.8,0,8.2-3.6,8.2-8.2V9.6" fill="#0F549D"/>
                          </g>
                          <g>
                            <path d="M16.6,25v7.7c0,4.3-5.5,6.5-9.5,6.5c-2.3,0-4.5-0.7-6.1-1.9v17h10.8c3.8,0,8.2-3.6,8.2-8.2" fill="#02375E"/>
                          </g>
                          <g>
                            <g>
                              <path d="M47.3,36.6h4.7c1.2,0,2.3-1.1,2.3-2.3s-1.1-2.3-2.3-2.3h-4.7V36.6z" fill="#146643"/>
                            </g>
                            <g>
                              <path d="M52.6,9.6c-4.9,0-8.5,3.1-8.5,8.4V25h12.6c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3c2,0,3.6,1.4,3.6,3.2     c0,1.8-1.5,3.2-3.6,3.2H44v16.3h10.8c3.8,0,8.2-3.6,8.2-8.2V9.6H52.6z" fill="#1BCC38"/>
                            </g>
                            <g>
                              <path d="M44,18.1V25h12.6c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3c2,0,3.6,1.4,3.6,3.2c0,1.8-1.5,3.2-3.6,3.2H44     v16.3h10.8c3.8,0,8.2-3.6,8.2-8.2V9.6" fill="#329947"/>
                            </g>
                            <g>
                              <path d="M59.9,28.3c0,1.8-1.5,3.3-3.3,3.3c2,0,3.6,1.4,3.6,3.2c0,1.8-1.5,3.2-3.6,3.2H44v16.3h10.8     c3.8,0,8.2-3.6,8.2-8.2" fill="#146643"/>
                            </g>
                            <g>
                              <path d="M54.2,28.7c0-1.2-1.1-2.3-2.3-2.3h-4.6v4.5H52C53.2,30.9,54.2,29.9,54.2,28.7z" fill="#146643"/>
                            </g>
                          </g>
                          <g>
                            <path d="M30.9,9.6c-4.9,0-8.5,3.1-8.5,8.4v8.6c1.2-1,2.7-1.7,4.3-1.7h11.4v2.4c-2.7-0.6-5.3-0.9-6.6-0.9    c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1c1.3,0,3.9-0.2,6.6-0.8V38H26.7c-1.6,0-3.2-0.6-4.3-1.7v18h10.8    c3.8,0,8.2-3.6,8.2-8.2V9.6H30.9z" fill="#E20E37"/>
                          </g>
                          <g>
                            <path d="M22.4,18.1v8.6c1.2-1,2.7-1.7,4.3-1.7h11.4v2.4c-2.7-0.6-5.3-0.9-6.6-0.9c-2.8,0-5.1,2.3-5.1,5.1    c0,2.8,2.3,5.1,5.1,5.1c1.3,0,3.9-0.2,6.6-0.8V38H26.7c-1.6,0-3.2-0.6-4.3-1.7v18h10.8c3.8,0,8.2-3.6,8.2-8.2V9.6" fill="#B41F36"/>
                          </g>
                          <g>
                            <path d="M38.1,25v2.4c-2.7-0.6-5.3-0.9-6.6-0.9c-2.8,0-5.1,2.3-5.1,5.1c0,2.8,2.3,5.1,5.1,5.1    c1.3,0,3.9-0.2,6.6-0.8V38H26.7c-1.6,0-3.2-0.6-4.3-1.7v18h10.8c3.8,0,8.2-3.6,8.2-8.2" fill="#720A1E"/>
                          </g>
                        </g>
                      </svg>
                      
                      <svg enable-background="new 0 0 64 64" height="50px" id="Layer_1" version="1.1" viewBox="0 0 64 64" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g>
                          <g>
                            <g>
                              <path d="M63.5,32c0,10.4-8.4,18.9-18.9,18.9c-10.4,0-18.9-8.5-18.9-18.9v0c0-10.4,8.4-18.9,18.8-18.9     C55.1,13.1,63.5,21.6,63.5,32C63.5,32,63.5,32,63.5,32z" fill="#FFB600"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M44.6,13.1c10.4,0,18.9,8.5,18.9,18.9c0,0,0,0,0,0c0,10.4-8.4,18.9-18.9,18.9c-10.4,0-18.9-8.5-18.9-18.9     " fill="#F7981D"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M44.6,13.1c10.4,0,18.9,8.5,18.9,18.9c0,0,0,0,0,0c0,10.4-8.4,18.9-18.9,18.9" fill="#FF8500"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M19.2,13.1C8.9,13.2,0.5,21.6,0.5,32c0,10.4,8.4,18.9,18.9,18.9c4.9,0,9.3-1.9,12.7-4.9l0,0h0     c0.7-0.6,1.3-1.3,1.9-2h-3.9c-0.5-0.6-1-1.3-1.4-1.9h6.7c0.4-0.6,0.8-1.3,1.1-2h-8.9c-0.3-0.6-0.6-1.3-0.8-2h10.4     c0.6-1.9,1-3.9,1-6c0-1.4-0.2-2.7-0.4-4H26.2c0.1-0.7,0.3-1.3,0.5-2h10.4c-0.2-0.7-0.5-1.4-0.8-2h-8.8c0.3-0.7,0.7-1.3,1.1-2h6.7     c-0.4-0.7-0.9-1.4-1.5-2h-3.7c0.6-0.7,1.2-1.3,1.9-1.9c-3.3-3.1-7.8-4.9-12.7-4.9C19.3,13.1,19.3,13.1,19.2,13.1z" fill="#FF5050"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M0.5,32c0,10.4,8.4,18.9,18.9,18.9c4.9,0,9.3-1.9,12.7-4.9l0,0h0c0.7-0.6,1.3-1.3,1.9-2h-3.9     c-0.5-0.6-1-1.3-1.4-1.9h6.7c0.4-0.6,0.8-1.3,1.1-2h-8.9c-0.3-0.6-0.6-1.3-0.8-2h10.4c0.6-1.9,1-3.9,1-6c0-1.4-0.2-2.7-0.4-4     H26.2c0.1-0.7,0.3-1.3,0.5-2h10.4c-0.2-0.7-0.5-1.4-0.8-2h-8.8c0.3-0.7,0.7-1.3,1.1-2h6.7c-0.4-0.7-0.9-1.4-1.5-2h-3.7     c0.6-0.7,1.2-1.3,1.9-1.9c-3.3-3.1-7.8-4.9-12.7-4.9c0,0-0.1,0-0.1,0" fill="#E52836"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <path d="M19.4,50.9c4.9,0,9.3-1.9,12.7-4.9l0,0h0c0.7-0.6,1.3-1.3,1.9-2h-3.9c-0.5-0.6-1-1.3-1.4-1.9h6.7     c0.4-0.6,0.8-1.3,1.1-2h-8.9c-0.3-0.6-0.6-1.3-0.8-2h10.4c0.6-1.9,1-3.9,1-6c0-1.4-0.2-2.7-0.4-4H26.2c0.1-0.7,0.3-1.3,0.5-2     h10.4c-0.2-0.7-0.5-1.4-0.8-2h-8.8c0.3-0.7,0.7-1.3,1.1-2h6.7c-0.4-0.7-0.9-1.4-1.5-2h-3.7c0.6-0.7,1.2-1.3,1.9-1.9     c-3.3-3.1-7.8-4.9-12.7-4.9c0,0-0.1,0-0.1,0" fill="#CB2026"/>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <path d="M26.1,36.8l0.3-1.7c-0.1,0-0.3,0.1-0.5,0.1c-0.7,0-0.8-0.4-0.7-0.6l0.6-3.5h1.1l0.3-1.9h-1l0.2-1.2h-2      c0,0-1.2,6.6-1.2,7.4c0,1.2,0.7,1.7,1.6,1.7C25.4,37.1,25.9,36.9,26.1,36.8z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M26.8,33.6c0,2.8,1.9,3.5,3.5,3.5c1.5,0,2.1-0.3,2.1-0.3l0.4-1.9c0,0-1.1,0.5-2.1,0.5      c-2.2,0-1.8-1.6-1.8-1.6h4.1c0,0,0.3-1.3,0.3-1.8c0-1.3-0.7-2.9-2.9-2.9C28.3,28.9,26.8,31.1,26.8,33.6z M30.3,30.7      c1.1,0,0.9,1.3,0.9,1.4H29C29,32,29.2,30.7,30.3,30.7z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M43,36.8l0.4-2.2c0,0-1,0.5-1.7,0.5c-1.4,0-2-1.1-2-2.3c0-2.4,1.2-3.7,2.6-3.7c1,0,1.8,0.6,1.8,0.6      l0.3-2.1c0,0-1.2-0.5-2.3-0.5c-2.3,0-4.6,2-4.6,5.8c0,2.5,1.2,4.2,3.6,4.2C41.9,37.1,43,36.8,43,36.8z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M15.1,28.9c-1.4,0-2.4,0.4-2.4,0.4l-0.3,1.7c0,0,0.9-0.4,2.2-0.4c0.7,0,1.3,0.1,1.3,0.7      c0,0.4-0.1,0.5-0.1,0.5s-0.6,0-0.9,0c-1.7,0-3.6,0.7-3.6,3c0,1.8,1.2,2.2,1.9,2.2c1.4,0,2-0.9,2.1-0.9l-0.1,0.8h1.8l0.8-5.5      C17.8,29,15.8,28.9,15.1,28.9z M15.5,33.4c0,0.3-0.2,1.9-1.4,1.9c-0.6,0-0.8-0.5-0.8-0.8c0-0.5,0.3-1.2,1.8-1.2      C15.4,33.4,15.5,33.4,15.5,33.4z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M19.7,37c0.5,0,3,0.1,3-2.6c0-2.5-2.4-2-2.4-3c0-0.5,0.4-0.7,1.1-0.7c0.3,0,1.4,0.1,1.4,0.1l0.3-1.8      c0,0-0.7-0.2-1.9-0.2c-1.5,0-3,0.6-3,2.6c0,2.3,2.5,2.1,2.5,3c0,0.6-0.7,0.7-1.2,0.7c-0.9,0-1.8-0.3-1.8-0.3l-0.3,1.8      C17.5,36.8,18,37,19.7,37z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M59.6,27.3L59.2,30c0,0-0.8-1-1.9-1c-1.8,0-3.4,2.2-3.4,4.8c0,1.6,0.8,3.3,2.5,3.3      c1.2,0,1.9-0.8,1.9-0.8l-0.1,0.7h2l1.5-9.6L59.6,27.3z M58.7,32.6c0,1.1-0.5,2.5-1.6,2.5c-0.7,0-1.1-0.6-1.1-1.6      c0-1.6,0.7-2.6,1.6-2.6C58.3,30.9,58.7,31.4,58.7,32.6z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M4.2,36.9l1.2-7.2l0.2,7.2H7l2.6-7.2l-1.1,7.2h2.1l1.6-9.6H8.9l-2,5.9l-0.1-5.9H3.9l-1.6,9.6H4.2z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M35.2,36.9c0.6-3.3,0.7-6,2.1-5.5c0.2-1.3,0.5-1.8,0.7-2.3c0,0-0.1,0-0.4,0c-0.9,0-1.6,1.2-1.6,1.2      l0.2-1.1h-1.9l-1.3,7.8H35.2z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M47.6,28.9c-1.4,0-2.4,0.4-2.4,0.4l-0.3,1.7c0,0,0.9-0.4,2.2-0.4c0.7,0,1.3,0.1,1.3,0.7      c0,0.4-0.1,0.5-0.1,0.5s-0.6,0-0.9,0c-1.7,0-3.6,0.7-3.6,3c0,1.8,1.2,2.2,1.9,2.2c1.4,0,2-0.9,2.1-0.9l-0.1,0.8h1.8l0.8-5.5      C50.4,29,48.3,28.9,47.6,28.9z M48.1,33.4c0,0.3-0.2,1.9-1.4,1.9c-0.6,0-0.8-0.5-0.8-0.8c0-0.5,0.3-1.2,1.8-1.2      C48,33.4,48,33.4,48.1,33.4z" fill="#FFFFFF"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M52,36.9c0.6-3.3,0.7-6,2.1-5.5c0.2-1.3,0.5-1.8,0.7-2.3c0,0-0.1,0-0.4,0c-0.9,0-1.6,1.2-1.6,1.2      l0.2-1.1h-1.9l-1.3,7.8H52z" fill="#FFFFFF"/>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <path d="M23,35.4c0,1.2,0.7,1.7,1.6,1.7c0.7,0,1.3-0.2,1.5-0.3l0.3-1.7c-0.1,0-0.3,0.1-0.5,0.1      c-0.7,0-0.8-0.4-0.7-0.6l0.6-3.5h1.1l0.3-1.9h-1l0.2-1.2" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M27.8,33.6c0,2.8,0.9,3.5,2.5,3.5c1.5,0,2.1-0.3,2.1-0.3l0.4-1.9c0,0-1.1,0.5-2.1,0.5      c-2.2,0-1.8-1.6-1.8-1.6h4.1c0,0,0.3-1.3,0.3-1.8c0-1.3-0.7-2.9-2.9-2.9C28.3,28.9,27.8,31.1,27.8,33.6z M30.3,30.7      c1.1,0,1.3,1.3,1.3,1.4H29C29,32,29.2,30.7,30.3,30.7z" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M43,36.8l0.4-2.2c0,0-1,0.5-1.7,0.5c-1.4,0-2-1.1-2-2.3c0-2.4,1.2-3.7,2.6-3.7c1,0,1.8,0.6,1.8,0.6      l0.3-2.1c0,0-1.2-0.5-2.3-0.5c-2.3,0-3.6,2-3.6,5.8c0,2.5,0.2,4.2,2.6,4.2C41.9,37.1,43,36.8,43,36.8z" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M12.4,31.1c0,0,0.9-0.4,2.2-0.4c0.7,0,1.3,0.1,1.3,0.7c0,0.4-0.1,0.5-0.1,0.5s-0.6,0-0.9,0      c-1.7,0-3.6,0.7-3.6,3c0,1.8,1.2,2.2,1.9,2.2c1.4,0,2-0.9,2.1-0.9l-0.1,0.8h1.8l0.8-5.5c0-2.3-2-2.4-2.8-2.4 M16.5,33.4      c0,0.3-1.2,1.9-2.4,1.9c-0.6,0-0.8-0.5-0.8-0.8c0-0.5,0.3-1.2,1.8-1.2C15.4,33.4,16.5,33.4,16.5,33.4z" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M17.5,36.8c0,0,0.6,0.2,2.3,0.2c0.5,0,3,0.1,3-2.6c0-2.5-2.4-2-2.4-3c0-0.5,0.4-0.7,1.1-0.7      c0.3,0,1.4,0.1,1.4,0.1l0.3-1.8c0,0-0.7-0.2-1.9-0.2c-1.5,0-2,0.6-2,2.6c0,2.3,1.5,2.1,1.5,3c0,0.6-0.7,0.7-1.2,0.7" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M59.2,30c0,0-0.8-1-1.9-1c-1.8,0-2.4,2.2-2.4,4.8c0,1.6-0.2,3.3,1.5,3.3c1.2,0,1.9-0.8,1.9-0.8l-0.1,0.7      h2l1.5-9.6 M59.1,32.6c0,1.1-0.9,2.5-2,2.5c-0.7,0-1.1-0.6-1.1-1.6c0-1.6,0.7-2.6,1.6-2.6C58.3,30.9,59.1,31.4,59.1,32.6z" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M4.2,36.9l1.2-7.2l0.2,7.2H7l2.6-7.2l-1.1,7.2h2.1l1.6-9.6H9.7l-2.8,5.9l-0.1-5.9H5.7l-3.4,9.6H4.2z" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M33.1,36.9h2.1c0.6-3.3,0.7-6,2.1-5.5c0.2-1.3,0.5-1.8,0.7-2.3c0,0-0.1,0-0.4,0c-0.9,0-1.6,1.2-1.6,1.2      l0.2-1.1" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M44.9,31.1c0,0,0.9-0.4,2.2-0.4c0.7,0,1.3,0.1,1.3,0.7c0,0.4-0.1,0.5-0.1,0.5s-0.6,0-0.9,0      c-1.7,0-3.6,0.7-3.6,3c0,1.8,1.2,2.2,1.9,2.2c1.4,0,2-0.9,2.1-0.9l-0.1,0.8h1.8l0.8-5.5c0-2.3-2-2.4-2.8-2.4 M49,33.4      c0,0.3-1.2,1.9-2.4,1.9c-0.6,0-0.8-0.5-0.8-0.8c0-0.5,0.3-1.2,1.8-1.2C48,33.4,49,33.4,49,33.4z" fill="#DCE5E5"/>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path d="M49.9,36.9H52c0.6-3.3,0.7-6,2.1-5.5c0.2-1.3,0.5-1.8,0.7-2.3c0,0-0.1,0-0.4,0c-0.9,0-1.6,1.2-1.6,1.2      l0.2-1.1" fill="#DCE5E5"/>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>

                      <svg height="50px" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="形状_1_3_" style="enable-background:new    ;">
                          <g id="形状_1">
                            <g>
                              <path d="M211.328,184.445l-23.465,144.208h37.542l23.468-144.208     H211.328z M156.276,184.445l-35.794,99.185l-4.234-21.358l0.003,0.007l-0.933-4.787c-4.332-9.336-14.365-27.08-33.31-42.223     c-5.601-4.476-11.247-8.296-16.705-11.559l32.531,124.943h39.116l59.733-144.208H156.276z M302.797,224.48     c0-16.304,36.563-14.209,52.629-5.356l5.357-30.972c0,0-16.534-6.288-33.768-6.288c-18.632,0-62.875,8.148-62.875,47.739     c0,37.26,51.928,37.723,51.928,57.285c0,19.562-46.574,16.066-61.944,3.726l-5.586,32.373c0,0,16.763,8.148,42.382,8.148     c25.616,0,64.272-13.271,64.272-49.37C355.192,244.272,302.797,240.78,302.797,224.48z M455.997,184.445h-30.185     c-13.938,0-17.332,10.747-17.332,10.747l-55.988,133.461h39.131l7.828-21.419h47.728l4.403,21.419h34.472L455.997,184.445z      M410.27,277.641l19.728-53.966l11.098,53.966H410.27z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#005BAC;"/>
                            </g>
                          </g>
                        </g>
                        <g id="形状_1_2_" style="enable-background:new    ;">
                          <g id="形状_1_1_">
                            <g>
                              <path d="M104.132,198.022c0,0-1.554-13.015-18.144-13.015H25.715     l-0.706,2.446c0,0,28.972,5.906,56.767,28.033c26.562,21.148,35.227,47.51,35.227,47.51L104.132,198.022z" style="fill-rule:evenodd;clip-rule:evenodd;fill:#F6AC1D;"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                      
                    </div>
                    <div class="col-md-5">
                      <div class="ctmListDetail btn">
                        <loaderBtn v-if="paymentLoader"/>
                        <button @click="requestUpgrade('stripe')" :disabled="tourIsActive" v-else>
                          Pay Via Card 
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="ctmListTitle">
                        <p>Bank Transfer</p>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="ctmSelectBox" id="country-selection">
                        <select name="country" v-model="selectedCountry" @change="countrySelection" :disabled="tourIsActive">
                            <option v-for="(country,index) in countriesList" :key="index" :value="country">{{country.name}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-if="selectedCountry && selectedCountry.name == 'Pakistan' && !amountLoader">
                <li>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="ctmListTitle">
                        <p>Account Title</p>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="ctmListDetail">
                        <p>MeriWebShop</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="ctmListTitle">
                        <p>Account No</p>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="ctmListDetail">
                        <p>8405-1223434213</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="ctmListTitle">
                        <p>IBAN</p>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="ctmListDetail">
                        <p>PK84051223434213</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="ctmListTitle">
                        <p>Amount to be paid</p>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="ctmListDetail">
                        <p>{{ amountToBePaid }}</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-md-5">
                      <div class="paymentSlipTitle">
                        <label for="feature_img">Upload Payment Slip</label>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="paymentSlipTitle">
                        <input type="file" @change.prevent="changeImage" id="feature_img" name="feature_img" />
                        <span class="text-danger" v-show="fieldRequired">Payment Slip Must Be Uploaded</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-else-if="amountLoader">
                <li>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btnLoaderCtm">
                        <div class="item loading-6">
                          <svg viewBox="25 25 50 50">
                            <circle cx="50" cy="50" r="20"></circle>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-if="notSupported">
                <li>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="ctmListDetail">
                        <p>Unfortunately, bank transfer is not available for the country you have selected.</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="paymentSlipBtn" v-if="selectedCountry && selectedCountry.name == 'Pakistan' && !amountLoader">
                <loaderBtn v-if="bankTransferLoader"/>
                <button @click="requestUpgrade('online-transfer')" :disabled="tourIsActive" v-else>
                  Submit Slip
                </button>
              </div>
              <!-- <div class="paymentSlipBtn">
                <button @click="requestUpgrade('online-transfer')">
                  Submit Slip
                </button>
                <button @click="requestUpgrade('credit-card')">
                  Pay Via Credit Card
                </button>
                <button @click="requestUpgrade('stripe')">
                  Pay Via Credit Card (Stripe)
                </button>
              </div> -->
            </div>
            <div class="modalCloseIcon">
              <button @click.prevent="paymentPopup = false" :disabled="tourIsActive">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.026" height="14.027" viewBox="0 0 14.026 14.027">
                  <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!-- payment information popup ends here -->

      </div>

      <div class="modalBg" :class="alfalahForm.isOpen ? 'modal-active' : ''">
        <div class="modalLayers" @click.prevent="closeForm"></div>
        <div class="modalBody">
          <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
            <div id="London" class="tabcontent">
              <div class="contaient-div horizontal-scroll-fix">
                <div class="titleActiveBox"></div>
                <form ref="alfalahRedirect" action="https://payments.bankalfalah.com/SSO/SSO/SSO" method="post" novalidate="novalidate">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="authToken"
                          name="authToken"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="RequestHash"
                          name="RequestHash"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="ChannelId"
                          name="ChannelId"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="Currency"
                          name="Currency"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="IsBIN"
                          name="IsBIN"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="ReturnURL"
                          name="ReturnURL"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="MerchantId"
                          name="MerchantId"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="StoreId"
                          name="StoreId"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="MerchantHash"
                          name="MerchantHash"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="MerchantUsername"
                          name="MerchantUsername"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="MerchantPassword"
                          name="MerchantPassword"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="TransactionTypeId"
                          name="TransactionTypeId"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="TransactionReferenceNumber"
                          name="TransactionReferenceNumber"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="formContentBox">
                        <input
                          type="text"
                          class="form-control"
                          id="TransactionAmount"
                          name="TransactionAmount"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="formCustomerBtn">
                        <button type="submit">Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modalCloseIcon">
            <button @click.prevent="closeForm">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

    </div>

    <!-- vue tour -->
    <v-tour name="subscriptionsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import subHeader from "@/components/subHeader.vue";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import _ from 'lodash';
import { MessageBox, Message } from 'element-ui'
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  metaInfo(){
    return {
      title: 'Settings | Subscription | YeetCommerce',
    };
  },
  data(){
    return {
      paymentPopup: false,
      selectedPackage: "",
      selectedSubscriptionType: "annual",
      maxDiscountValue:0,
      featuresList:[],
      requestLoader:false,
      amountLoader:false,
      paymentLoader:false,
      bankTransferLoader:false,
      notSupported:false,
      amountToBePaid:0,
      form: {
        attachment: null,
        attachment_src: null,
      },
      packagesList: [],
      countriesList:[],
      selectedCountry:{},
      types: ["annual", "monthly"],
      fieldRequired: false,
      cancelLoader:false,
      alfalahKeys: {
        key1: "eYsHqEFryHV54nXx",
        key2: 8898017291444946,
      },
      alfalahForm: {
        authToken: "",
        requestHash: "",
        ChannelId: 1001,
        IsBIN: 0,
        currency: "PKR",
        returnURL: "https://dashboard.meriwebshop.com/subscription",
        merchantId: 21310,
        storeId: "027973",
        merchantHash: "OUU362MB1uq+K7u4SocG8CPrYHIUkWREBzQS5d4biaRxmn09gPtiPBlDf1y8SsUlliCu6JcZC1M=",
        merchantUsername: "osuker",
        merchantPassword: "0PGMSB9n4vxvFzk4yqF7CA==",
        transactionTypeId: 3,
        transactionReferenceNumber: 0,
        transactionAmount: 10,
        isOpen: false,
      },

      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //subscriptions steps
        {
          target: '#package-switch',
          content: `<strong>Package Duration Switch</strong><br>You can switch between monthly & yearly prices of the packages using this switch.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#package-details-box',
          content: `<strong>Package Details</strong><br>Packages details & features are displayed here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#current-package',
          content: `<strong>Current Package</strong><br>Your current package will display this badge.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
        {
          target: '#upgrade-package',
          content: `<strong>Upgrade Package</strong><br>You can upgrade your package by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
      ],
      tourIsActive:false
    }
  },
  components:{
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed: {
    ...mapGetters({
      subscription: "subscription_module/subscription",
      subscriptionLoader: "subscription_module/isPending",
      subscriptionPackages: "subscription_module/packages",
      storeAllCountries: 'countries_module/allCountries',
    }),
  },
  watch:{
    storeAllCountries:{
      handler:function(value){

        this.countriesList = value;

      },deep:true
    },
    subscriptionPackages:{
      handler:function(value){

        this.packagesList = _.cloneDeep(value);

        let tempFeatures = JSON.parse(this.packagesList[0].features);

        this.packagesList = this.packagesList.map((pkg) =>{

          let features = JSON.parse(pkg.features);

          let featureValues = Object.values(features);

          pkg.features = featureValues;

          return pkg;

        })

        // this.packagesList = this.packagesList.sort((a,b) => a.monthly_price - b.monthly_price);

        this.packagesList = this.packagesList.sort((a, b) => {
          if (a.package_type === "custom" && b.package_type !== "custom") return 1;
          if (a.package_type !== "custom" && b.package_type === "custom") return -1;
          return a.monthly_price - b.monthly_price;
        });

        this.maxDiscountValue = Math.max(...this.packagesList.map(pkg => pkg.annual_discount));

        tempFeatures.forEach((feature) => {

          this.featuresList.push(feature.title);

        });

        // Object.keys(tempFeatures).forEach(key =>{

        //   this.featuresList.push(key);

        // });

      },deep:true
    }
  },
  methods: {
    async countrySelection(){

      if(this.selectedCountry.name == "Pakistan"){

        this.notSupported = false;
        this.amountLoader = true;

        try{
          let res = await this.$axios.get('https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert',{
            params:{
              from: 'USD',
              to: 'PKR',
              amount: this.selectedSubscriptionType == 'annual' ? this.selectedPackage.yearly_price : this.selectedPackage.monthly_price
            },
            headers:{
              ['X-RapidAPI-Key'] : '86ee3747fcmsh8674df23e300237p1d1c10jsn8d621943972b',
              ['X-RapidAPI-Host'] : 'currency-conversion-and-exchange-rates.p.rapidapi.com'
            }
          })
          if(res.data.success){

            this.amountToBePaid = (res.data.result).toFixed(2);

          }
        }catch(error){

          this.$message({
            type: "error",
            showClose: true,
            message: "Something went wrong. Try again later.",
          });

        }finally{

          this.amountLoader = false;

        }

      }else{

        this.notSupported = false;
        this.amountLoader = true;

        setTimeout(()=>{
          this.notSupported = true;
          this.amountLoader = false;
        },500)

      }
    },
    openPaymentInformationModal(pkg){

      this.selectedPackage = pkg;
      this.selectedCountry = {};
      this.amountToBePaid = 0;
      this.notSupported = false;
      this.paymentPopup = true;

    },
    async changeImage(e){

      const { files } = e.target;
      this.form.attachment = files[0];
      this.form.attachment_src = URL.createObjectURL(files[0]);

      this.fieldRequired = false;

    },
    getPkgIndex(pkg){

      let subscriptionIndex = this.packagesList.findIndex(item => item.id === this.subscription.package.id);

      let pkgIndex = this.packagesList.findIndex(item => item.id === pkg.id);

      if(subscriptionIndex < pkgIndex){

        return false;

      }else{

        return true;

      }

    },
    downGradePopup(){

      Swal.fire(
        "Downgrade Guide",
        "contact support team to downgrade your package",
        "warning"
      );

    },
    async initiateSubscriptionChangeRequest(pkg){

      MessageBox.confirm(
        'Do you really want to initiate the subscription change request?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async ()=>{

        if(pkg.package_type == 'custom'){

          this.$notify({
            type: 'info',
            title: 'Cannot Proceed',
            message: "Custom package cannot be subscribed.",
          });

          return 

        }

        this.selectedPackage = pkg;

        let formData = new FormData();

        formData.append('package_id',this.selectedPackage.id);
        formData.append('subscription_type',this.selectedSubscriptionType);
        formData.append('platform','web');

        this.requestLoader = true;
        try{
          let res = await this.$axios.post('/subscription/plan/change/request',formData)
          if(res.data.status_code == '1171'){

            window.location.replace(res.data.session_url);

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes('already subscribed') ||
            error.response.data.error.includes('Allocated space') ||
            error.response.data.error.includes('exists an active request')){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.requestLoader = false;

        }

      }).catch(()=>{})

    },
    async requestUpgrade(option){

      if(option == "online-transfer"){

        if(this.form.attachment == null){

          this.fieldRequired = true;

          return;

        }

        let formData = new FormData();

        formData.append("payment_type", "online transfer");
        formData.append("attachment", this.form.attachment);
        formData.append("package_id", this.selectedPackage.id);
        formData.append("subscription_type", this.selectedSubscriptionType);

        this.bankTransferLoader = true;
        try{
          let res = await this.$axios.post("/subscription/change/request",formData);
          if(res.data.status_code == "1171"){

            this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

            this.$message({
              type: "success",
              showClose: true,
              message: res.data.message,
            });

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("not enough for your current resources")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });
              
            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.paymentPopup = false;
          this.bankTransferLoader = false;

        }

      }else if(option == "credit-card"){

        let formData = new FormData();

        formData.append("payment_type", "credit card");
        formData.append("package_id", this.selectedPackage.id);
        formData.append("subscription_type", this.selectedSubscriptionType);

        try{
          let res = await this.$axios.post("/subscription/change/request",formData);
          if (res.data.status_code == "1171") {

            this.alfalahForm.transactionAmount = res.data.request.amount;
            this.alfalahForm.transactionReferenceNumber = res.data.request.id;

            this.creditCard();

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("not enough for your current resources")){

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.paymentPopup = false;

        }

      }else if(option == "stripe"){

        let formData = new FormData();

        formData.append("payment_type", "stripe");
        formData.append("package_id", this.selectedPackage.id);
        formData.append("subscription_type", this.selectedSubscriptionType);
        
        try{
          let res = await this.$axios.post("/subscription/change/request",formData);
          if(res.data.status_code == "1171"){

            this.stripePaymentLink(res.data.request.id);

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("not enough for your current resources")) {

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: "error",
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.paymentLoader = true;
          this.paymentPopup = false;

        }

      }

    },
    async creditCard(){

      let mapString1 =
        "HS_RequestHash=&HS_IsRedirectionRequest=0&HS_ChannelId=" +
        this.alfalahForm.ChannelId +
        "&HS_ReturnURL=" +
        this.alfalahForm.returnURL +
        "&HS_MerchantId=" +
        this.alfalahForm.merchantId +
        "&HS_StoreId=" +
        this.alfalahForm.storeId +
        "&HS_MerchantHash=" +
        this.alfalahForm.merchantHash +
        "&HS_MerchantUsername=" +
        this.alfalahForm.merchantUsername +
        "&HS_MerchantPassword=" +
        this.alfalahForm.merchantPassword +
        "&HS_TransactionReferenceNumber=" +
        this.alfalahForm.transactionReferenceNumber +
        "&handshake=&";

      let requestHash = this.generateRequestHash(
        mapString1,
        this.alfalahKeys.key1,
        this.alfalahKeys.key2
      );
      
      let formData = new URLSearchParams();

      formData.append("HS_ChannelId", this.alfalahForm.ChannelId);
      formData.append("HS_MerchantId", this.alfalahForm.merchantId);
      formData.append("HS_StoreId", this.alfalahForm.storeId);
      formData.append("HS_ReturnURL", this.alfalahForm.returnURL);
      formData.append("HS_MerchantHash", this.alfalahForm.merchantHash);
      formData.append("HS_MerchantUsername", this.alfalahForm.merchantUsername);
      formData.append("HS_MerchantPassword", this.alfalahForm.merchantPassword);
      formData.append("HS_TransactionReferenceNumber",this.alfalahForm.transactionReferenceNumber);
      formData.append("HS_RequestHash", requestHash);
      formData.append("HS_IsRedirectionRequest", 0);

      await fetch("https://payments.bankalfalah.com/HS/HS/HS", {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {

        return res.json();

      }).then(async (data) => {

        let authToken = data.AuthToken;

        let mapString2 =
        "AuthToken=" +
        authToken +
        "&RequestHash=" +
        "&ChannelId=" +
        this.alfalahForm.ChannelId +
        "&Currency=" +
        this.alfalahForm.currency +
        "&IsBIN=" +
        this.alfalahForm.IsBIN +
        "&ReturnURL=" +
        this.alfalahForm.returnURL +
        "&MerchantId=" +
        this.alfalahForm.merchantId +
        "&StoreId=" +
        this.alfalahForm.storeId +
        "&MerchantHash=" +
        this.alfalahForm.merchantHash +
        "&MerchantUsername=" +
        this.alfalahForm.merchantUsername +
        "&MerchantPassword=" +
        this.alfalahForm.merchantPassword +
        "&TransactionTypeId=" +
        this.alfalahForm.transactionTypeId +
        "&TransactionReferenceNumber=" +
        this.alfalahForm.transactionReferenceNumber +
        "&TransactionAmount=" +
        this.alfalahForm.transactionAmount +
        "&run=&";

        let requestHash2 = this.generateRequestHash(
          mapString2,
          this.alfalahKeys.key1,
          this.alfalahKeys.key2
        );

        this.alfalahForm.requestHash = requestHash2;
        this.alfalahForm.authToken = authToken;

        document
        .querySelector("#authToken")
        .setAttribute("value", authToken);
        document
        .querySelector("#RequestHash")
        .setAttribute("value", this.alfalahForm.requestHash);
        document
        .querySelector("#ChannelId")
        .setAttribute("value", this.alfalahForm.ChannelId);
        document
        .querySelector("#Currency")
        .setAttribute("value", this.alfalahForm.currency);
        document
        .querySelector("#IsBIN")
        .setAttribute("value", this.alfalahForm.IsBIN);
        document
        .querySelector("#ReturnURL")
        .setAttribute("value", this.alfalahForm.returnURL);
        document
        .querySelector("#MerchantId")
        .setAttribute("value", this.alfalahForm.merchantId);
        document
        .querySelector("#StoreId")
        .setAttribute("value", this.alfalahForm.storeId);
        document
        .querySelector("#MerchantHash")
        .setAttribute("value", this.alfalahForm.merchantHash);
        document
        .querySelector("#MerchantUsername")
        .setAttribute("value", this.alfalahForm.merchantUsername);
        document
        .querySelector("#MerchantPassword")
        .setAttribute("value", this.alfalahForm.merchantPassword);
        document
        .querySelector("#TransactionTypeId")
        .setAttribute("value", this.alfalahForm.transactionTypeId);
        document
        .querySelector("#TransactionReferenceNumber")
        .setAttribute("value", this.alfalahForm.transactionReferenceNumber);
        document
        .querySelector("#TransactionAmount")
        .setAttribute("value", this.alfalahForm.transactionAmount);

        this.$refs.alfalahRedirect.submit();

        // await fetch('https://payments.bankalfalah.com/SSO/SSO/SSO',{
        //   method: 'POST',
        //   body: formData,
        //   headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded',
        //   },
        // })

      });
    },
    generateRequestHash(mapString, key1, key2){

      let requestHash = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(mapString.substr(0, mapString.length - 1)),
        CryptoJS.enc.Utf8.parse(key1),
        {
          keySize: 128 / 8,
          iv: CryptoJS.enc.Utf8.parse(key2),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      return requestHash.toString();

    },
    async stripePaymentLink(requestId){

      let formData = new FormData(); 

      formData.append("package_id", this.selectedPackage.id);
      formData.append("subscription_type", this.selectedSubscriptionType);
      formData.append("subscription_request_id",requestId);

      try{
        let res = await this.$axios.post('/subscription/stripe/payment-link',formData);
        if(res.data.status_code == '1340'){

          window.location.replace(res.data.url);

        }
      }catch(error){

        if(error.response){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.paymentLoader = false;

      }

    },
    async subscriptionRequestCancellationHandle(){

      MessageBox.confirm(
        'Do you really want to cancel the subscription change request?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      ).then(async ()=>{

        let formData = new FormData();

        formData.append('subscription_request_id',this.subscription.last_request.id)

        this.cancelLoader = true
        try{
          let res = await this.$axios.post('/subscription/request/cancel',formData);
          if(res.data.status_code == '11741'){

            this.$notify({
              title: 'Success',
              type: 'success',
              message: res.data.message
            });

            this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

          }
        }catch(error){

          if(error.response){

            if(error.response.data.error.includes("has already") ||
              error.response.data.error.includes('not associated')){

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.error,
              });

            }else{

              this.$message({
                type: 'error',
                showClose: true,
                message: error.response.data.message,
              });

            }

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.message,
            });

          }

        }finally{

          this.cancelLoader = false;

        }

      }).catch(() => {})

    },

    packagePricing(listedPackage){

      if(listedPackage.package_type == 'fixed'){

        if(this.selectedSubscriptionType && this.selectedSubscriptionType == 'annual'){

          let annualPerMonthPrice = 0.00;

          if(listedPackage.annual_discount == 0){

            annualPerMonthPrice = listedPackage.yearly_price

          }else{

            annualPerMonthPrice = (listedPackage.yearly_price / 12).toFixed(2)

          }

          return `$${annualPerMonthPrice}`

        }else{

          return `$${listedPackage.monthly_price}`

        }

      }else{

        return "Let's Talk";

      }

    },

    //tour methods
    startSubscriptionsTour(){

      this.$tours.subscriptionsTour.start();

      this.tourIsActive = true;

      if(this.paymentPopup){
        
        this.paymentPopup = false;

      }
    },
    handleNextStep(currentStep){

      // if(this.$tours.subscriptionsTour.currentStep + 1 == 9){

      //   this.paymentPopup = true;

      // }

    },
    handlePreviousStep(currentStep){

      // if(this.$tours.subscriptionsTour.currentStep == 9){

      //   this.paymentPopup = false;

      // }

    },
    handleTourStop(){

      // this.paymentPopup = false;

      this.tourIsActive = false;

    },
    handleTourFinish(){

      // this.paymentPopup = false;

      this.tourIsActive = false;

    },
    handleTourSkip(){
      
      // this.paymentPopup = false;

      this.tourIsActive = false;

    },


    //fetch module data if needed
    async fetchModuleData(){

      try{

        if(this.subscription.length == 0){

          this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

        }

        if(!this.subscriptionPackages || this.subscriptionPackages.length == 0){

          this.$store.dispatch("subscription_module/fetchSubscriptionPackagesRequest");

        }else{

          this.packagesList = _.cloneDeep(this.subscriptionPackages);

          let tempFeatures = JSON.parse(this.packagesList[0].features);

          this.packagesList = this.packagesList.map((pkg) =>{
            
            let features = JSON.parse(pkg.features);

            let featureValues = Object.values(features);

            pkg.features = featureValues;

            return pkg;

          });

          // this.packagesList = this.packagesList.sort((a,b) => a.monthly_price - b.monthly_price);

          this.packagesList = this.packagesList.sort((a, b) => {
            if (a.package_type === "custom" && b.package_type !== "custom") return 1;
            if (a.package_type !== "custom" && b.package_type === "custom") return -1;
            return a.monthly_price - b.monthly_price;
          });

          this.maxDiscountValue = Math.max(...this.packagesList.map(pkg => pkg.annual_discount));

          // Object.keys(tempFeatures).forEach(key =>{
          //   this.featuresList.push(key);
          // })

          tempFeatures.forEach((feature) => {

            this.featuresList.push(feature.title);

          });

        }

      }catch(error){

        this.$message({
          type: 'error',
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }

      try{

        if(!this.storeAllCountries || this.storeAllCountries.length == 0){

          this.$store.dispatch('countries_module/fetchAllCountries');

        }else{

          this.countriesList = this.storeAllCountries;

        }

      }catch(error){

        this.$message({
          type: 'error',
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }

    },

  },
  mounted(){

    //fetching module data
    this.fetchModuleData();

  },
  async beforeMount(){

    // if(this.subscription.length == 0){

    //   this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

    // }

    // if(!this.subscriptionPackages || this.subscriptionPackages.length == 0){

    //   this.$store.dispatch("subscription_module/fetchSubscriptionPackagesRequest");

    // }else{
      
    //   this.packagesList = _.cloneDeep(this.subscriptionPackages);

    //   let tempFeatures = JSON.parse(this.packagesList[0].features);

    //   this.packagesList = this.packagesList.map((pkg) =>{
        
    //     let features = JSON.parse(pkg.features);

    //     let featureValues = Object.values(features);

    //     pkg.features = featureValues;

    //     return pkg;

    //   });

    //   // this.packagesList = this.packagesList.sort((a,b) => a.monthly_price - b.monthly_price);

    //   this.packagesList = this.packagesList.sort((a, b) => {
    //     if (a.package_type === "custom" && b.package_type !== "custom") return 1;
    //     if (a.package_type !== "custom" && b.package_type === "custom") return -1;
    //     return a.monthly_price - b.monthly_price;
    //   });
      
    //   this.maxDiscountValue = Math.max(...this.packagesList.map(pkg => pkg.annual_discount));

    //   // Object.keys(tempFeatures).forEach(key =>{
    //   //   this.featuresList.push(key);
    //   // })

    //   tempFeatures.forEach((feature) => {

    //     this.featuresList.push(feature.title);

    //   });

    // }

    // try{

    //   if(!this.storeAllCountries || this.storeAllCountries.length == 0){

    //     this.$store.dispatch('countries_module/fetchAllCountries');

    //   }else{

    //     this.countriesList = this.storeAllCountries;

    //   }

    // }catch(error){

    //   this.$message({
    //     type: 'error',
    //     showClose: true,
    //     message: error.response ? error.response.data.message : error.message,
    //   });

    // }



    //alfalah payment verification start
    // if(this.$route.query.O){

    //   let orderId = this.$route.query.O;

    //   let formData = new FormData();

    //   formData.append("merchant_id", this.alfalahForm.merchantId);
    //   formData.append("alfalah_store_id", this.alfalahForm.storeId);
    //   formData.append("order_id", orderId);

    //   this.$router.push(this.$route.path);

    //   try{
    //     let res = await this.$axios.post("/subscription/verify/card-payment",formData);
    //     if(res.data.status_code == "1174"){

    //       this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

    //       this.$notify({
    //         title: "success",
    //         type: "success",
    //         message: res.data.message,
    //       });

    //     }
    //   }catch(error){

    //     if(error.response){

    //       if(error.response.data.error.includes("Transaction failed")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else if(error.response.data.error.includes("No unverified payment found")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else if(error.response.data.error.includes("Request has already")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else{

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.message,
    //         });

    //       }

    //     }else{

    //       this.$message({
    //         type: 'error',
    //         showClose: true,
    //         message: error.message,
    //       });

    //     }

    //   }finally{

    //     this.$router.replace({'query': null});

    //   }

    // }
    //alfalah payment verification end

    // if(this.$route.query.session_id){
      
    //   let formData = new FormData();

    //   formData.append("merchant_id", this.alfalahForm.merchantId);
    //   formData.append("alfalah_store_id", this.alfalahForm.storeId);
    //   formData.append("order_id", orderId);

    //   this.$router.push(this.$route.path);

    //   try{
    //     let res = await this.$axios.post("/subscription/verify/card-payment",formData);
    //     if(res.data.status_code == "1174"){
    //       this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

    //       this.$notify({
    //         title: "success",
    //         type: "success",
    //         message: res.data.message,
    //       });

    //     }
    //   }catch(error){

    //     if(error.response){

          // if(error.response.data.error.includes("Transaction failed")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else if(error.response.data.error.includes("No unverified payment found")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else if(error.response.data.error.includes("Request has already")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else{

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.message,
    //         });

    //       }

    //     }else{

        //   this.$message({
        //     type: 'error',
        //     showClose: true,
        //     message: error.message,
        //   });

        // }

    //   }finally{

    //     this.$router.replace({'query': null});

      // }

    // }
    //alfalah payment verification end

    //       this.$notify({
    //         title: "success",
    //         type: "success",
    //         message: res.data.message,
    //       });

    //     }
    //   }catch(error){

    //     if(error.response){

    //       if (error.response.data.error.includes("Transaction failed")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else if(error.response.data.error.includes("No unverified payment found")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else if(error.response.data.error.includes("Request has already")){

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.error,
    //         });

    //       }else{

    //         this.$message({
    //           type: "error",
    //           showClose: true,
    //           message: error.response.data.message,
    //         });

    //       }

    //     }else{

    //       this.$message({
    //         type: 'error',
    //         showClose: true,
    //         message: error.message,
    //       });
  
    //     }

    //   }finally{

    //     this.$router.replace({'query': null});

    //   }
      
    // }

  },
};
</script>